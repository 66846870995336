<template>
  <div class="type-indicator">
    <component :is="iconType" />
    <span>{{ typeName }}</span>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import IconFileEarmarkText from '~icons/bi/file-earmark-text'
import IconBook from '~icons/bi/book'
import IconLock from '~icons/bi/lock'
import IconJournalText from '~icons/bi/journal-text'

// Props
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  typeName: {
    type: String,
    default: '',
  },
})

// Computed
const iconType = computed(() => {
  switch (props.type) {
    case 'restricted':
      return IconLock
    case 'book':
      return IconBook
    case 'subchapter':
    case 'chapter':
      return IconJournalText
    default:
      return IconFileEarmarkText
  }
})
</script>

<style lang="scss">
@import '~/assets/scss/variables';
.type-indicator {
  font-size: rem(14);
  text-transform: uppercase;
  span {
    padding-right: 4px;
  }
  svg {
    padding-right: 4px;
  }
  a:hover {
    text-decoration: underline;
  }
}

.type-indicator-icon {
  display: flex;
  align-items: center;

  svg {
    display: inline-block;
    width: 0.9em;
    height: 0.9em;
  }
}
</style>
