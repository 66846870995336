<template>
  <article class="snippet">
    <nuxt-link :to="to" class="snippet-body">
      <figure v-if="type === 'book'" class="snippet-image">
        <div class="book-cover">
          <img :src="image" :alt="title" :title="title" />
        </div>
      </figure>
      <h2 class="snippet-title h5" v-html="title"></h2>
      <p v-if="mappedSubtitle" class="snippet-subtitle h6 mt-1 mb-2">
        {{ mappedSubtitle }}
      </p>
      <p class="snippet-text" v-html="text" />
    </nuxt-link>
    <aside class="snippet-aside">
      <SnippetTypeIcon :type="type" :type-name="typeName">
        <template v-if="authorLink && authorName">
          <span class="font-weight-light">{{ $texts('from', 'Von') }} </span>
          <nuxt-link :to="authorLink">{{ authorName }}</nuxt-link>
        </template>
      </SnippetTypeIcon>
      <SnippetTypeIcon v-if="restricted" type="restricted">
        <nuxt-link :to="{ name: 'login' }">{{
          $texts('restricted', 'Mit Abonnement')
        }}</nuxt-link>
      </SnippetTypeIcon>
    </aside>
  </article>
</template>

<script setup lang="ts">
const { $texts } = useNuxtApp()

const props = defineProps({
  to: String,
  title: String,
  subtitle: String,
  text: String,
  authorName: String,
  authorLink: String,
  type: String,
  typeName: String,
  accessRestriction: String,
  collectionName: String,
  passages: [String, Array],
  image: String,
})

const restricted = computed(() => {
  return props.accessRestriction === 'Mit Abonnement'
})
const mappedSubtitle = computed(() => {
  if (props.passages && props.passages.length > 0) {
    const separator = $texts('verse_separator', '; ')
    if (typeof props.passages === 'object') {
      return props.passages.join(separator)
    }
    return props.passages
  }
  if (props.subtitle) {
    return props.subtitle
  }
  return ''
})
</script>
